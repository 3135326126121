<template>
  <div :class="classList.container" :name="name">
    <span :class="classList.sidelabel" v-if="sideLabel">
      {{ sideLabel }}
    </span>

    <label v-if="label" :for="id" :class="derivedLabelClass">{{ label }}</label>
    <input
      :id="id"
      :ref="id"
      :class="classList.input"
      :type="inputType"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :name="name"
      v-model="innerValue"
      @input="handleInput($event.target.value)"
      @blur="handleBlur($event.target.value)"
      @keypress="handleKeyPress"
      @focus="handleTextFieldFocus"
    />

    <img
      v-if="isVisible"
      src="@/assets/images/icons/general/Visibility-off.svg"
      class="csn-password-eye"
      @click="toggleVisibility"
    />
    <img
      v-else
      src="@/assets/images/icons/general/Visibility-on.svg"
      class="csn-password-eye"
      @click="toggleVisibility"
    />

    <Balloon :text="message" :arrowPosition="balloonArrowPosition" />
  </div>
</template>

<script>
import { BalloonArrowPosition, INPUT_CONTAINER_CLASS } from '@/constants'
import { inputMixin } from '@/mixins'

export default {
  name: 'PasswordField',
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
  },
  mixins: [inputMixin],
  props: {
    readonly: Boolean,
    autocomplete: {
      type: String,
      default: 'on',
    },
    sideLabel: String,
  },
  data: () => ({
    isVisible: false,
  }),
  computed: {
    classList() {
      return {
        sidelabel: 'csn-textfield-sidelabel',
        input: [
          'form-control form-control',
          this.inputClass,
          this.isVisible ? 'csn-password-input-visible' : '',
        ],
        container: [
          INPUT_CONTAINER_CLASS,
          this.containerClass,
          this.sideLabel ? 'csn-textfield-with-sidelabel' : '',
          'csn-password-field',
        ],
      }
    },
    balloonArrowPosition: () => BalloonArrowPosition.TOP_LEFT,
    inputType() {
      return this.isVisible ? 'text' : 'password'
    },
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === 'enter') {
        this.$emit('enter')
        event.target.blur()
      }
    },
    handleTextFieldFocus() {
      this.$refs[this.id].removeAttribute('readonly')
      this.$emit('focus')
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible
    },
  },
}
</script>
